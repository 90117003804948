import { CaretRightOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import { serviceDetails, serviceItemList } from '../../utils/pageDetails';

const ServiceCard = () => {
    const [ isActive, setIsActive ] = useState(1);
    const [ selectedItems, setSelectedItem ] = useState([]);

    useEffect(() => {
        setSelectedItem(serviceItemList.filter(item => item.service_id === 1));
    }, []);

    const handleSetActive = (value, e) => {
        e.stopPropagation();
        setIsActive(value);
        setSelectedItem(serviceItemList.filter(item => item.service_id === value));
    };
  return (
    <Row className="w-[750px] pb-10">
        <Col lg={10} sm={10} xs={10} className='border-r border-r-[#DFDFDF]'>
            <div className='w-full flex flex-col gap-5 px-6 py-6'>
                {serviceDetails.map((service,i) => {
                    return (
                        <div key={i} className={`flex items-center justify-between px-5 ${isActive === service.id ? "activelink" : "animate"}`} onClick={(e)=> handleSetActive(service.id, e)}>
                            <p className={`font-semibold`} to="ggg">
                                {service.title}
                            </p>
                            <NavLink to={`/services/${service.slug}`}>
                            {isActive === service.id && <CaretRightOutlined className="text-primary" />}
                            </NavLink>
                        </div>
                    )
                })} 
            </div>
        </Col>

        <Col lg={14} sm={14} xs={14} className='px-6 py-4'>
            {isActive === 1 && (
                <div className='flex flex-col gap-4 animate-[pulse_2s_ease]'>
                    <p className="font-semibold text-xl text-primary">Cybersecurity Consulting Services</p>
                    <p className="text-primary">Empower your organisation with scalable, secure, and efficient cloud solutions that optimise your IT infrastructure and drive innovation.</p>

                    <Row className="border-t border-t-[#DFDFDF] pt-2" gutter={[15, 15]}>
                        {selectedItems.map((item, i) => {
                            return (
                                <Col lg={12} sm={12} xs={12} key={i}>
                                    <Link to={`/services/${item.slug}/${item.id}`} className='text-servicelink font-medium'>{item.title}</Link>
                                </Col>
                            )
                        })}
                    </Row>
                </div>
            )}

            {isActive === 2 && (
                <div className='flex flex-col gap-4 animate-[pulse_2s_ease]'>
                    <p className="font-semibold text-xl text-primary">Operational Technology Security Services</p>
                    <p className="text-primary">Empower your organisation with scalable, secure, and efficient cloud solutions that optimise your IT infrastructure and drive innovation.</p>

                    <Row className="border-t border-t-[#DFDFDF] pt-2" gutter={[15, 15]}>
                        {selectedItems.map((item, i) => {
                            return (
                                <Col lg={12} sm={12} xs={12} key={i}>
                                    <Link to={`/services/${item.slug}/${item.id}`} className='text-servicelink font-medium'>{item.title}</Link>
                                </Col>
                            )
                        })}
                    </Row>
                </div>
            )}

            {isActive === 3 && (
                <div className='flex flex-col gap-4 animate-[pulse_2s_ease]'>
                    <p className="font-semibold text-xl text-primary">Data Privacy Services</p>
                    <p className="text-primary">Empower your organisation with scalable, secure, and efficient cloud solutions that optimise your IT infrastructure and drive innovation.</p>

                    <Row className="border-t border-t-[#DFDFDF] pt-2" gutter={[15, 15]}>
                        {selectedItems.map((item, i) => {
                            return (
                                <Col lg={12} sm={12} xs={12} key={i}>
                                    <Link to={`/services/${item.slug}/${item.id}`} className='text-servicelink font-medium'>{item.title}</Link>
                                </Col>
                            )
                        })}
                    </Row>
                </div>
            )}

            {isActive === 4 && (
                <div className='flex flex-col gap-4 animate-[pulse_2s_ease]'>
                    <p className="font-semibold text-xl text-primary">Technology Services</p>
                    <p className="text-primary">Empower your organisation with scalable, secure, and efficient cloud solutions that optimise your IT infrastructure and drive innovation.</p>

                    <Row className="border-t border-t-[#DFDFDF] pt-2" gutter={[15, 15]}>
                        {selectedItems.map((item, i) => {
                            return (
                                <Col lg={12} sm={12} xs={12} key={i}>
                                    <Link to={`/services/${item.slug}/${item.id}`} className='text-servicelink font-medium'>{item.title}</Link>
                                </Col>
                            )
                        })}
                    </Row>
                </div>
            )}
        </Col>
    </Row>
  )
}

export default ServiceCard