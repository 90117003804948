import WebsiteContainer from "../components/WebsiteContainer";
import { Tag1, Tag2, Tag3, Tag4 } from "../assest/icons";
import Blog from "../components/Blog";
import { Button } from "antd";
import { WomanLaptop, WorkSpace } from "../assest/image";
import { motion } from "framer-motion";

function Blogs() {
  return (
    <WebsiteContainer>
      <main>
        <section className="header bg-bg min-h-[60vh] flex items-center h-full">
          <article className="max-w-[80%] h-full relative  p-24 m-auto w-full flex flex-col gap-5 text-center">
            <h2 className="text-6xl z-50 text-primary font-bold ">Our Blog</h2>
            <p className="text-xl z-50 text-[#555555] font-medium">
              Subscribe to get our daily, weekly, and monthly articles ....
            </p>
            <div className="relative z-50">
              <input
                type="text"
                className="p-4 ring-1 ring-gray-200 rounded-md shadow-md w-full py-8 bg-white"
                placeholder="Enter your email"
              />
              <Button
                type="primary"
                className="right-5  top-[50%] -translate-y-[50%]  rounded-md absolute text-white font-bold p-4 px-8 "
              >
                Subscribe
              </Button>
            </div>
            <motion.img
              initial={{ x: 50 }}
              animate={{ x: 0 }}
              transition={{ duration: 1 }}
              src={Tag1}
              className="absolute z-5 -right-14 top-0"
              alt=""
            />
            <motion.img
              initial={{ y: 20 }}
              animate={{ y: 0 }}
              transition={{ duration: 1 }}
              src={Tag3}
              className="absolute z-5 -right-14 -bottom-3"
              alt=""
            />
            <motion.img
              initial={{ x: -20 }}
              animate={{ x: 0 }}
              transition={{ duration: 1 }}
              src={Tag2}
              className="absolute z-5 -left-28 -bottom-8"
              alt=""
            />
            <motion.img
              initial={{ y: -20 }}
              animate={{ y: 0 }}
              transition={{ duration: 1 }}
              src={Tag4}
              className="absolute z-5 left-20
             -top-5"
              alt=""
            />
          </article>
        </section>

        <section className="flex flex-col p-12 gap-16 bg-white">
          <h3 className="text-3xl text-primary font-medium">Blog Posts</h3>
          <section className="grid bg-gray-100  grid-cols-3 grid-rows-1 gap-10">
            <Blog
              BlogImage={WorkSpace}
              BlogTitle={"What is Risk Management"}
              ReadTime={"8 mins"}
              Tag={"Risk"}
              BlogDescription={
                "How do you create compelling presentations that wow your colleagues and impress your managers?"
              }
              Author={"Olivia Ryke"}
              CreationDate={"20 Jan 2022"}
            />
            <Blog
              BlogImage={WomanLaptop}
              BlogTitle={"What is Risk Management"}
              ReadTime={"8 mins"}
              Tag={"Risk"}
              BlogDescription={
                "How do you create compelling presentations that wow your colleagues and impress your managers?"
              }
              Author={"Olivia Ryke"}
              CreationDate={"20 Jan 2022"}
            />
            <Blog
              BlogImage={WorkSpace}
              BlogTitle={"What is Risk Management"}
              ReadTime={"8 mins"}
              Tag={"Risk"}
              BlogDescription={
                "How do you create compelling presentations that wow your colleagues and impress your managers?"
              }
              Author={"Olivia Ryke"}
              CreationDate={"20 Jan 2022"}
            />
            <Blog
              BlogImage={WomanLaptop}
              BlogTitle={"What is Risk Management"}
              ReadTime={"8 mins"}
              Tag={"Risk"}
              BlogDescription={
                "How do you create compelling presentations that wow your colleagues and impress your managers?"
              }
              Author={"Olivia Ryke"}
              CreationDate={"20 Jan 2022"}
            />
            <Blog
              BlogImage={WorkSpace}
              BlogTitle={"What is Risk Management"}
              ReadTime={"8 mins"}
              Tag={"Risk"}
              BlogDescription={
                "How do you create compelling presentations that wow your colleagues and impress your managers?"
              }
              Author={"Olivia Ryke"}
              CreationDate={"20 Jan 2022"}
            />
            <Blog
              BlogImage={WomanLaptop}
              BlogTitle={"What is Risk Management"}
              ReadTime={"8 mins"}
              Tag={"Risk"}
              BlogDescription={
                "How do you create compelling presentations that wow your colleagues and impress your managers?"
              }
              Author={"Olivia Ryke"}
              CreationDate={"20 Jan 2022"}
            />
          </section>
        </section>
      </main>
    </WebsiteContainer>
  );
}

export default Blogs;
