import React, { useEffect, useState } from 'react'
import WebsiteContainer from '../components/WebsiteContainer'
import Faqs from '../components/extras/Faqs'
import { Outlet, useParams } from 'react-router-dom'
import { imageBakground, serviceDetails, serviceItemList } from '../utils/pageDetails'
import ServiceIndividual from '../components/cards/ServiceIndividual'
import { Button, Col, Image, Row } from 'antd';

const ServiceItem = () => {
    const { id } = useParams();
    const [ selectedService, setSelectedService ] = useState(null);
    const [ selectedServiceItems, setSelectedServiceItems ] = useState([]);

    useEffect(() => {
        if(id) {
            const service = serviceDetails.find(serviceDetails => serviceDetails.slug === id);
            const serviceItem = serviceItemList.filter(serviceDetails => serviceDetails.slug === id);
            setSelectedService(service);
            setSelectedServiceItems(serviceItem);
        }
    }, [id]);

  return (
    <WebsiteContainer active="services" showBgImg>
        <div className='pb-6'>
            <div style={{backgroundImage: `url(${imageBakground})`, backgroundColor: "#F7F7FF", height: 600}} className="bg-repeat bg-blend-multiply bg-transparent px-20 py-6 flex-col justify-center items-center">
                <Row gutter={[15, 15]} className='h-full'>
                    <Col lg={14} sm={12} xs={24}  className="flex flex-col gap-3 justify-center h-full">
                        <p className="text-primary font-bold text-[64px] leading-[5rem]">{selectedService && selectedService.title}</p>
                        <p className='text-[#555555] text-xl w-5/6'>{selectedService && selectedService.description}</p>

                        <div className='mt-6'>
                            <Button type='primary' style={{height: 50}}>
                                Book a Free Consultation
                            </Button>
                        </div>
                    </Col>

                    <Col lg={10} sm={12} xs={24} className='flex justify-center'>
                        <Image src={selectedService && selectedService.image} alt={selectedService && selectedService.title}  preview={false} />
                    </Col>
                </Row>
            </div>
            <div className='bg-white px-20 py-10 flex flex-col gap-8'>
                <p className='text-primary font-medium text-3xl text-center'>{selectedService && selectedService.title}</p>

                <ServiceIndividual services={selectedServiceItems} />
            </div>
            <div className='bg-white px-20 py-5'>
                <Faqs />
            </div>
        </div>
        <Outlet />
    </WebsiteContainer>
  )
}

export default ServiceItem