"use client";
import { Woman1 } from "../assest/image";
import { Phone, Location, Mail } from "../assest/icons";
import WebsiteContainer from "../components/WebsiteContainer";
import { Button, Form, Input, message } from "antd";
import { ArrowLeft } from "lucide-react";
import { useState } from "react";
import { contactUs } from "../Api/contact";


function Contact() {
  const [form] = Form.useForm();
  const [continueForm, setContinueForm] = useState(false);
  const [formData, setFormData] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const [ loading, setLoading ] = useState(false);

  const handleContinue = () => {
    form
      .validateFields()
      .then((values) => {
        setFormData(values);
        setContinueForm(true);
      })
      .catch(() => {
        console.log("something went wrong!");
      });
  };

  const onFinish = () => {
    form
    .validateFields()
    .then((values) => {
      const data = {
        ...formData,
        ...values,
      };
      setLoading(true);
      contactUs(data)
      .then((res) => {
        if(res.status === 200 || res.status === 201) {
          setLoading(false);
          messageApi.success(res.data.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        messageApi.error(err.response.data.message)
      });
    })
    .catch((err) => {
      console.log(err);
      messageApi.error("Validation error, Please try again later!")
    });
  };
  return (
    <WebsiteContainer>
      {contextHolder}
      <main className="bg-bg">
        {/**Contact-us Header Section */}
        <section className="header min-h-[60vh] flex items-center h-full">
          <article className="max-w-[90%] h-full  p-24 m-auto w-full flex flex-col gap-5 text-center">
            <h2 className="text-4xl text-primary font-bold ">
              Let us know how we can support your business's protection and
              development
            </h2>
            <p className="text-lg font-medium">
              We are always here to listen, research, and provide solutions to
              your business needs.
            </p>
          </article>
        </section>
        {/**Contact-us Form Section*/}
        <section className="grid grid-cols-[500px_1fr] pt-16 w-full p-12 gap-20">
          <div className="flex flex-1 flex-col justify-between gap-8">
            <img className="relative z-50" src={Woman1} alt="woman working" />
            <ol className="flex flex-col p-8 pl-12 relative *:z-50 *:relative font-medium after:-top-12 after:left-[29px] after:z-0 after:absolute after: after:h-full *:text-xl after:w-[2px] after:bg-primary list-disc gap-5 *:marker:text-3xl *:marker:text-primary">
              <li> Discover our services </li>
              <li> Ask questions </li>
              <li> Call expert guidance </li>
            </ol>
          </div>
          <div className="rounded-2xl h-[90%] p-10 py-12 w-full bg-primary">
            {/**Form*/}
            <Form
              layout="vertical"
              className={`flex flex-col ${
                continueForm ? "" : "justify-between"
              } h-full`}
              form={form}
              onFinish={onFinish}
            >
              <Form.Item className="border-none">
                <legend>
                  <h3 className="text-center font-medium text-xl text-white">
                    Fill this form to get expert guidance
                  </h3>
                </legend>
              </Form.Item>
              {!continueForm ? (
                <>
                  <Form.Item
                    label="What's your name"
                    name={"firstname"}
                    rules={[
                      { required: true, message: "Your Firstname is required" },
                    ]}
                  >
                    <Input
                      className="p-3 rounded-none"
                      placeholder="First Name"
                    />
                  </Form.Item>
                  <Form.Item
                    name={"lastname"}
                    rules={[
                      { required: true, message: "Your Lastname is required" },
                    ]}
                  >
                    <Input
                      className="p-3 rounded-none"
                      placeholder="Last Name"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Business email address"
                    name={"email"}
                    rules={[
                      {
                        required: true,
                        message: "Your Business email address is required",
                      },
                    ]}
                  >
                    <Input
                      className="p-3 rounded-none"
                      placeholder="you@company.com"
                    />
                  </Form.Item>

                  <Form.Item
                    label="Designation"
                    name={"designation"}
                    rules={[
                      {
                        required: true,
                        message: "Your Work designation is required",
                      },
                    ]}
                  >
                    <Input
                      className="p-3 rounded-none"
                      placeholder="e.g IT Manager, Security analyst, CEO etc"
                    />
                  </Form.Item>
                </>
              ) : (
                <div>
                  <Form.Item label="How can we help you?" name={"message"}>
                    <Input.TextArea
                      className="p-3 rounded-none"
                      placeholder="Enter your thoughts here..."
                    />
                  </Form.Item>
                  <Form.Item>
                    <p className="text-white text-lg">
                      Smartsafe may contact you from time to time to keep you
                      informed of security news and events. You will always have
                      an option to change or unsubscribe.
                    </p>
                  </Form.Item>
                </div>
              )}

              <div>
                {!continueForm ? (
                  <Form.Item className="flex item-center justify-center ">
                    <Button
                      onClick={handleContinue}
                      className="px-16"
                      type="default"
                    >
                      Continue
                    </Button>
                  </Form.Item>
                ) : (
                  <div className="flex items-center gap-5 justify-center">
                    <Form.Item className="flex item-center justify-center ">
                      <Button
                        className="bg-white flex items-center justify-center  text-secondary border-secondary border-4 p-0 w-14"
                        onClick={() => setContinueForm(false)}
                        type="default"
                      >
                        <ArrowLeft className="w-8 h-8" />
                      </Button>
                    </Form.Item>
                    <Form.Item className="flex item-center justify-center ">
                      <Button
                        htmlType="submit"
                        className="px-16"
                        type="default"
                        loading={loading}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </div>
                )}
              </div>
            </Form>
          </div>
        </section>
        {/**General Contact Info Section*/}
        <section className="flex flex-col p-12 gap-12 bg-white">
          <h3 className="text-3xl  text-primary font-medium">
            General Contact Info
          </h3>
          <article className="grid grid-cols-[300px_300px_300px] justify-between items-stretch">
            <div className="flex flex-col items-center gap-5">
              <img src={Mail} alt="icon" />
              <div className="text-center">
                <h4 className="font-medium">Email Address</h4>
                <a href="mailto:Info@smartsafeuk.com">
                  Info@smartsafeuk.com
                </a>
                {/* <p className="underline"></p> */}
              </div>
            </div>
            <div className="flex flex-col items-center gap-5">
              <img src={Phone} alt="icon" />
              <div className="text-center">
                <h4 className="font-medium">Phone Number</h4>
                <p>+44 (0)207 175 3082</p>
              </div>
            </div>
            <div className="flex flex-col items-center gap-5">
              <img src={Location} alt="icon" />
              <div className="text-center">
                <h4 className="font-medium">Office Address</h4>
                <p className="text-balance">
                  133 Creek Rd, London SE8 3BN, United Kingdom
                </p>
              </div>
            </div>
          </article>
        </section>
      </main>
    </WebsiteContainer>
  );
}

export default Contact;
