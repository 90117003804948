import React, { useState } from 'react';
import { Button, Col, Dropdown, Image, Layout, Row } from "antd";
import { Footerlogo, Menulogo } from '../assest/logo';
import { Link, NavLink, useNavigate } from "react-router-dom";
import { DownOutlined } from '@ant-design/icons';
import ServiceCard from './menu/ServiceCard';
import { imageBakground, serviceDetails } from '../utils/pageDetails';

const { Header, Footer } = Layout;
const WebsiteContainer = ({ children, active, showBgImg }) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const dropdownItem = [
        {
            key: '1',
            label: (
                <ServiceCard />
            ),
        },
       
    ];
    const dropdownItem2 = [
        {
            key: '1',
            label: (
                <Link to="/blogs" className="py-6 px-7">Blog</Link>
            ),
        },
        {
            key: '2',
            label: (
                <Link to="/project" className="py-6 px-7">Project</Link>
            ),
        },
           
    ];
  return (
    <Layout>
        <Header style={{backgroundImage: showBgImg ? `url(${imageBakground})` : undefined}} className={`bg-bg flex items-center justify-between px-10 py-16 sticky top-0 z-10`}>
            <div className={`bg-[#FAFAFC] flex items-center justify-between px-4 py-2 w-full`}>
                <Link to="/">
                    <Image src={Menulogo} alt='smartsafe logo' preview={false} />
                </Link>

                <div className='flex items-center gap-6'>
                    <NavLink to="/" end className="font-semibold">
                        Home
                    </NavLink>

                    <div className="font-semibold">
                        <Dropdown 
                            menu={{ items: dropdownItem, style:{backgroundColor: "#fff"}, className: "hover:bg-white" }}
                            open={open}
                            onOpenChange={(flag) => setOpen(flag)}
                            placement="bottom"
                        >
                            <div className='flex gap-2'>
                                <NavLink to="/services">
                                    Services
                                </NavLink>
                                <DownOutlined className='cursor-pointer ml-1 font-semibold' />
                            </div>
                            
                            
                        </Dropdown>
                    </div>
                    <NavLink to="/about" className="font-semibold">About Us</NavLink>
                    <div className="font-semibold second_item">
                        <Dropdown 
                            menu={{ items: dropdownItem2 }}
                            className='second_item'
                        >
                            <span>Resources <DownOutlined className='cursor-pointer ml-1 font-semibold' /></span>
                        </Dropdown>
                    </div>
                    {/* <NavLink to="/resources" className="font-semibold"></NavLink> */}
                </div>
                
                <Button
                    type="primary"
                    onClick={() => navigate("/contact")}
                >
                    Contact Us
                </Button>
            </div>
         
        </Header>

        <Layout>
            {children}
        </Layout>

        <Footer className="bg-primary text-white">
            <Row className='pb-12 pt-8'>
                <Col lg={9} sm={12} xs={12} className='flex flex-col gap-6'>
                    <Link to="/">
                        <Image src={Footerlogo} alt='footer logo' width={319} preview={false}/>
                    </Link>
                    
                    <Link to="https://www.linkedin.com/company/66637468/admin/dashboard/" target="_blank">
                        <Button type='primary' className="bg-[#3DAFE2] md:w-[319px]">
                            Connect with us on LinkedIn
                        </Button>
                    </Link>
                </Col>

                <Col lg={5} sm={12} xs={12} className='flex flex-col gap-3 '>
                    <p className='text-[#98A2B3] font-bold'>Company</p>
                    <Link to="/">
                        Home
                    </Link>

                    <Link to="/about">
                        About
                    </Link>

                    <Link to="/contact">
                        Contact
                    </Link>
                </Col>

                <Col lg={5} sm={12} xs={12} className='flex flex-col gap-3 '>
                    <p className='text-[#98A2B3] font-bold'>Our Services</p>

                    {serviceDetails.map((details, i) => {
                        return (
                            <Link key={i} to={`/services/${details.slug}`}>
                                {details.title}
                            </Link>
                        )
                    })}
                </Col>

                <Col lg={5} sm={12} xs={12} className='flex flex-col gap-3 '>
                    <p className='text-[#98A2B3] font-bold'>Resources</p>
                    <Link to="/blog">
                    Blog
                    </Link>

                    <Link to="/project">
                    Projects
                    </Link>

                    <Link to="/privacy">
                    Privacy policy
                    </Link>

                    <Link to="/terms">
                    Terms & conditions
                    </Link>
                </Col>
            </Row>

            <Row className="border-t border-t-[#475467] py-6">
                <p>© 2025 SMARTSAFE CONSULTING. All rights reserved.</p>
            </Row>
        </Footer>
    </Layout>
  )
}

export default WebsiteContainer