import React from 'react';
import { Menuside } from '../assest/image';
import { Button, Image } from 'antd';
import 'react-phone-input-2/lib/style.css'
import WebsiteContainer from '../components/WebsiteContainer';
import AdvertCard from '../components/extras/AdvertCard';
import ServiceCard from '../components/cards/ServiceCard';
import ReadyToElevate from '../components/cards/ReadyToElevate';
import ProjectCarousel from '../components/carousels/ProjectCarousel';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  return (
    <WebsiteContainer active="home">
      <div>
        <div className='px-20 flex items-center justify-left  bg-[#F7F7FF] py-6 relative h-[600px] pb-14'>
          <div  className='flex flex-col gap-4 w-3/5'>
            <p className='text-[64px] font-bold leading-[5rem]'>Build, Secure, Scale, and Manage Your <span className='text-textsec'>Business</span> Solutions.</p>
            <p className='text-[#555555] text-xl w-4/5'>We are dedicated to enabling thousands of exceptional outcomes for our clients daily. Our core mission is to build and protect your information and infrastructure, while empowering you to focus on driving your business forward confidently and securely.</p>

            <div className='flex items-center gap-4 mt-6'>
              <Button type="primary" className='w-[250px]' onClick={() => navigate("/services")}>Our Services</Button>
              <Button type="default" className='w-[300px]'>Book a Free Consultation</Button>
            </div>
          </div>

          <div className='flex items-center justify-start  w-1/2 absolute right-[70px] top-[0px]'>
            <div >
            <Image src={Menuside} alt="side menu" preview={false} style={{width: "700px", height: "600px"}} />
            </div>
           
          </div>
        </div>

        <div className='bg-[#F7F7FF] px-20 py-5'>
          <AdvertCard />
        </div>

        <div className='bg-white py-8 px-20 pt-12'>
          <ServiceCard />
        </div>

        <div className='bg-white py-8 px-20 pt-12'>
          <ProjectCarousel />
        </div>
        <div>
          <ReadyToElevate />
        </div>
      </div>
    </WebsiteContainer>    
  )
}

export default Home