import React from 'react'
import { serviceDetails } from '../../utils/pageDetails'
import { Col, Row } from 'antd'
import { Link } from 'react-router-dom'
import { ArrowRightOutlined } from '@ant-design/icons'

const ServiceBlueCard = () => {
  return (
    <div>
        <p className='text-primary text-2xl font-bold text-center mb-6'>Our Services</p>
        <Row gutter={[15, 15]} className='min-h-[400px] bg-white'>
            {serviceDetails.map((details, index) => {
                return (
                    <Col key={index} lg={6} sm={12} xs={24} className='h-full hoverEffect'>
                        <div className='bg-primary text-white px-5 py-10 flex flex-col gap-6 h-full'>
                            <p className='font-semibold text-xl h-12'>{details.title}</p>
                            <p className='font-medium text-base h-24'>{details.description}</p>

                            <Link className='mt-12' to={`/services/${details.slug}`}>View Services <ArrowRightOutlined className='text-white ml-2 font-bold' /></Link>
                        </div>
                    </Col>
                )
            })}
        </Row>
    </div>
  )
}

export default ServiceBlueCard