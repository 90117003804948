import React from 'react';
import { Advert1, Advert10, Advert11, Advert12, Advert13, Advert14, Advert15, Advert16, Advert17, Advert2, Advert3, Advert4, Advert5, Advert6, Advert7, Advert8, Advert9 } from '../../assest/logo/advert';
import { Carousel, Image } from 'antd';

const images = [
    Advert1,
    Advert2,
    Advert3,
    Advert4,
    Advert5,
    Advert6,
    Advert7,
    Advert8,
    Advert9,
    Advert10,
    Advert11,
    Advert12,
    Advert13,
    Advert14,
    Advert15,
    Advert16,
    Advert17
]
const AdvertCard = () => {
  return (

    <div className='bg-[#F7F7FF] flex items-center gap-4'>
        <p className="border-r-2 border-r-[#B3B3B3] text-[#1E1E1E] text-2xl pr-0 w-[15%]">Trusted By</p>
        <div className='w-[85%] items-center'>
            <Carousel dots={false} slidesToShow={7} autoplay autoplaySpeed={8000}>
                {images.map((image, index) =><div key={index}>
                    <Image src={image} alt="caousek" preview={false} /> 
                </div>)}
            
            </Carousel>
        </div>
      
    </div>
  )
}

export default AdvertCard