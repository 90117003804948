import { Carousel } from 'antd'
import React from 'react'
import { projectListItems } from '../../utils/pageDetails'
import ProjectCards from '../cards/ProjectCards'

const ProjectCarousel = () => {
  return (
    <div className='flex flex-col gap-6'>
        <p className='font-medium text-3xl text-center text-[#1E1E1E]'>Some projects we've built</p>

        <Carousel dots={false} slidesToShow={2} autoplay autoplaySpeed={7000}>
            {projectListItems.map((project, index) =>
            <div key={index}>
                <ProjectCards project={project} /> 
            </div>)}
        </Carousel>
    </div>
  )
}

export default ProjectCarousel