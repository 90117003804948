import { Button, Image } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';

const ProjectCards = ({ project }) => {
    
  return (
    <div className='mx-3 hoverEffect'>
        <div style={{backgroundImage: `url(${project.image})`}} className="h-[300px] w-full bg-cover bg-no-repeat"></div>
        {/* <Image src={project.image} /> */}

        <div className='px-4 py-6 flex-col gap-4'>
            <Image src={project.logo} preview={false} />
            <p>{project.description}</p>

            <Link to={`${project.url}`} target="_blank">
                <Button type="primary" className='h-[50px] mt-8'>
                    View Project
                </Button>
            </Link>
        </div>
        
    </div>
  )
}

export default ProjectCards;