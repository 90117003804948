import {
  LNG,
  AAGolf,
  DarkAAGolf,
  IMV,
  IMVLIGHT,
  SmartLearn,
  LNGLIGHT,
  SmartLearnLight,
} from "../assest/icons";
import {
  AAgolf as AAgolfDashboard,
  SmartLearn as SmartLearnDashboard,
  IMV as IMVDashboard,
  LNG as LNGDashboard,
} from "../assest/image";
import WebsiteContainer from "../components/WebsiteContainer";
import { Button } from "antd";
import { motion } from "framer-motion";


function Project() {
  return (
    <WebsiteContainer>
      <main>
        {/**Hero Section*/}
        <section className="header bg-bg min-h-[60vh] flex items-center h-full">
          <article className=" h-full  p-24 m-auto w-full flex flex-col gap-5 text-center">
            <h2 className="text-4xl text-primary font-bold ">
              Innovative Solutions for Cybersecurity Driven Companies.
            </h2>
            <p className="text-lg font-medium">
              Strengthening Cybersecurity for Businesses Like Yours
            </p>
          </article>
        </section>
        {/**Project Section*/}
        <section className="flex flex-col p-16 px-20 gap-16 bg-section-background">
          <h3 className="text-3xl text-center text-primary font-medium">
            Our Solution to some Business
          </h3>
          <section className="grid grid-cols-2 gap-10 justify-between items-stretch">
            <motion.article
              // whileHover={{ scale: 1.02, transition: { duration: 0.5 } }}
              className=" ring-1 ring-gray-200 rounded-xl bg-white overflow-hidden hoverEffect"
            >
              <div className="bg-secondary1 p-8 rounded-t-2xl overflow-hidden">
                <div className="flex">
                  <p className="uppercase text-xl border-r font-bold text-white pr-4">
                    Project
                  </p>
                  <img className="pl-4" src={AAGolf} alt="" />
                </div>
                <img
                  src={AAgolfDashboard}
                  className="ml-40 mt-5 -mb-8"
                  alt=""
                />
              </div>
              <div className="p-5 flex flex-col rounded-b-xl items-start gap-4">
                <img className="w-48" src={DarkAAGolf} alt="" />
                <p className="font-medium">
                  AAGOLF is a non profit golfing group, consisting of corporate
                  members playing Golf together, where every member have a stake
                  in the group.
                </p>
                <a
                  className="bg-primary text-white p-4 px-8 rounded-md hover:text-primary hover:bg-transparent border-primary border-2  font-bold"
                  href="https://aagolf.co.uk/"
                  target="blank"
                >
                  View Project
                </a>
              </div>
            </motion.article>

            <motion.article
              // whileHover={{ scale: 1.02 }}
              className="ring-1 ring-gray-200  rounded-xl hoverEffect"
            >
              <div className="bg-secondary2 p-8 rounded-t-2xl overflow-hidden">
                <div className="flex">
                  <p className="uppercase text-xl border-r font-bold text-white pr-4">
                    Project
                  </p>
                  <img className="pl-4" src={IMVLIGHT} alt="" />
                </div>
                <img src={IMVDashboard} className="ml-40 mt-5 -mb-8" alt="" />
              </div>
              <div className="p-5 flex bg-white rounded-b-xl flex-col items-start gap-4">
                <img className="w-48" src={IMV} alt="" />
                <p className="font-medium">
                  IMV will equip people and organizations with accredited,
                  future-proof skills that make them employable, competitive,
                  and ready for the evolving job market.
                </p>
                <a
                  className="bg-primary text-white p-4 px-8 rounded-md hover:text-primary hover:bg-transparent border-primary border-2  font-bold"
                  href="https://www.imovarsity.com/"
                  target="blank"
                >
                  View Project
                </a>
              </div>
            </motion.article>

            <motion.article
              // whileHover={{ scale: 1.02 }}
              className="ring-1 ring-gray-200 bg-white rounded-xl hoverEffect"
            >
              <div className="bg-secondary3 p-8 rounded-t-2xl overflow-hidden">
                <div className="flex">
                  <p className="uppercase text-xl border-r font-bold text-white pr-4">
                    Project
                  </p>
                  <img className="pl-4" src={SmartLearnLight} alt="" />
                </div>
                <img
                  src={SmartLearnDashboard}
                  className="ml-40 mt-5 -mb-8"
                  alt=""
                />
              </div>
              <div className="p-5 flex flex-col items-start gap-4">
                <img className="w-48" src={SmartLearn} alt="" />
                <p className="font-medium">
                  Smart Learning UK specializes in providing job-ready online
                  training focused on IT security through hands-on and
                  project-based learning.
                </p>
                <a
                  className="bg-primary text-white p-4 px-8 rounded-md hover:text-primary hover:bg-transparent border-primary border-2  font-bold"
                  href="https://www.smartlearninguk.com/"
                  target="blank"
                >
                  View Project
                </a>
              </div>
            </motion.article>

            <motion.article
              // whileHover={{ scale: 1.02 }}
              className="ring-1 ring-gray-200 bg-white rounded-xl hoverEffect"
            >
              <div className="bg-secondary4 p-8 rounded-t-2xl overflow-hidden">
                <div className="flex">
                  <p className="uppercase text-xl border-r font-bold text-white pr-4">
                    Project
                  </p>
                  <img className="pl-4" src={LNGLIGHT} alt="" />
                </div>
                <img src={LNGDashboard} className="ml-40 mt-5 -mb-8" alt="" />
              </div>
              <div className="p-5 flex flex-col items-start gap-4">
                <img className="w-48" src={LNG} alt="" />
                <p className="font-medium text-lg text-[#61676C]">
                  Nigeria LNG Limited (NLNG) is a liquefied natural gas
                  (LNG)-producing company and a liquefied natural gas plant on
                  Bonny Island, Nigeria.
                </p>
                <a
                  className="bg-primary text-white p-4 px-8 rounded-md hover:text-primary hover:bg-transparent border-primary border-2  font-bold"
                  href="https://www.nlng.com/index.html"
                  target="blank"
                >
                  View Project
                </a>
              </div>
            </motion.article>
          </section>
        </section>
        {/**Elevate your career section*/}
        <section className="bg-card p-24 flex flex-col items-center justify-center">
          <div className="m-auto flex flex-col gap-5 items-center text-center max-w-[70%]">
            <h3 className="text-4xl text-primary font-bold">
              Ready to Elevate to the Next Level?
            </h3>
            <p className="text-lg text-secondaryforeground font-medium">
              Begin your journey to strengthen cyber resilience, enhance your
              security posture, and minimize cyber risks with our
              all-encompassing Security Advisory services.
            </p>
            <Button type="primary">Contact Us</Button>
          </div>
        </section>
      </main>
    </WebsiteContainer>
  );
}

export default Project;
