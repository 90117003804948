import {
  Here,
  Ellipse,
  DataSafe,
  TopPriority,
  LeftDataSafe,
  Serve,
  SecureBro,
  Understand,
  Proof,
  Prevention,
  BestPractices,
  FailProof,
  Success,
  Recognize,
  SecureBro2,
  Login_rafiki,
  Fingerprint_pana,
} from "../assest/icons";
import WebsiteContainer from "../components/WebsiteContainer";
import { Button } from "antd";
import { AnimatePresence, motion } from "framer-motion";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import { useState } from "react";

const getItems = (panelStyle) => [
  {
    key: 1,
    label: "What does Smartsafe Consulting do?",
    children: (
      <p>
        Smartsafe Consulting is dedicated to enabling exceptional outcomes for
        clients daily by protecting their information and infrastructure,
        allowing them to focus on growing their business confidently and
        securely.
      </p>
    ),
    style: panelStyle,
  },
  {
    key: 2,
    label: "What is Smartsafe Consulting's core mission?",
    children: (
      <p>
        Our core mission is to protect your information and infrastructure,
        empowering you to drive your business forward confidently and securely.
      </p>
    ),
    style: panelStyle,
  },
  {
    key: 3,
    label: "What makes Smartsafe Consulting different?",
    children: (
      <p>
        We recognize that success requires more than just security; it demands
        resilience and adaptability in today's dynamic landscape. We provide the
        tools and expertise to achieve both.
      </p>
    ),
    style: panelStyle,
  },
  {
    key: 4,
    label: "What kind of outcomes can I expect from Smartsafe Consulting?",
    children: (
      <p>
        {" "}
        We help businesses by safeguarding their critical information and
        infrastructure, enabling them to operate without fear of cyber threats
        and focus on their core business objectives.
      </p>
    ),
    style: panelStyle,
  },
];

const Items = [
  {
    icon: Serve,
    title: "We serve you",
  },
  {
    icon: Understand,
    title: "We understand you",
  },
  {
    icon: Proof,
    title: "We futureproof your buiness",
  },
  {
    icon: Prevention,
    title: "We are prevention activists",
  },
  {
    icon: BestPractices,
    title: "We implement best practices.",
  },
  {
    icon: FailProof,
    title: "We deliver fail-proof solution",
  },
  {
    icon: Success,
    title: "We are consistent in success and excellence.",
  },
  {
    icon: Recognize,
    title: "We  recognise the power of the people.",
  },
];

const slideItems = [
  {
    icon: SecureBro,
    heading: "Cybersecurity Consulting Services",
    details:
      "Advanced strategies to protect against emerging cyber threats and safeguard your digital assets.",
  },
  {
    icon: SecureBro2,
    heading: "Operational Technology Security Services",
    details:
      "Tailored security solutions for critical infrastructure, ensuring the seamless integration of safety and security.",
  },
  {
    icon: Login_rafiki,
    heading: "Data Privacy Services",
    details:
      "Robust compliance frameworks to help you navigate global data protection regulations and build trust with your stakeholders.",
  },
  {
    icon: Fingerprint_pana,
    heading: "Technology Services",
    details:
      "Innovative IT solutions designed to optimise operations, enhance scalability, and maintain a secure environment.",
  },
];

function About() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const divItems = {
    hidden: { y: 100, opacity: 0 },
    show: (index) => ({
      y: 0,
      opacity: 1,
      transition: {
        ease: "easeInOut",
        delay: index * 0.05, // Sequential delay for each item
      },
    }),
  };

  const panelStyle = {
    marginBottom: 24,
    background: "#F1F1FF",
    borderRadius: 0,
    border: "none",
  };

  return (
    <WebsiteContainer>
      <main className="bg-bg">
        <section className="flex flex-col items-center h-full header overflow-hidden">
          <article className="max-w-[75%] h-full relative p-24 m-auto w-full flex flex-col gap-8 text-center">
            <h2 className="text-4xl text-primary leading-[60px]  font-bold ">
              We Enable Thousands of Exceptional{" "}
              <span className="text-secondary p-3 rounded-xl bg-primary">
                Outcomes
              </span>{" "}
              For Our Clients Daily.
            </h2>
            <p className="text-lg font-medium text-balance">
              Our team delivers high-value consulting services that help clients
              explore greenfield opportunities, enabling them to grow their
              operations and meet local and global market demands.
            </p>

            <div className="flex gap-5 m-auto">
              <Button type="primary">Our Services</Button>
              <Button type="default">Book A Call</Button>
            </div>

            <motion.img
              initial={{ x: 20 }}
              animate={{ x: 1 }}
              transition={{ duration: 1.5 }}
              src={TopPriority}
              className="absolute top-0 -right-20"
              alt=""
            />
            <motion.img
              initial={{ x: 0 }}
              animate={{ x: 20 }}
              transition={{ duration: 1.5 }}
              src={Here}
              className="absolute bottom-24 -left-20"
              alt=""
            />
            <motion.img
              initial={{ x: 20 }}
              animate={{ x: 0 }}
              transition={{ duration: 1.5 }}
              src={DataSafe}
              className="absolute -right-20 bottom-20"
              alt=""
            />
            <motion.img
              initial={{ x: 0 }}
              animate={{ x: 20 }}
              transition={{ duration: 1.5 }}
              src={LeftDataSafe}
              className="absolute -left-32 top-36"
              alt=""
            />
          </article>

          <img src={Ellipse} className="w-full" alt="" />
        </section>

        <section className="flex flex-col w-full p-12 gap-2">
          <h3 className="text-3xl text-primary font-medium">About Us</h3>
          <article className="grid grid-cols-[600px_500px] justify-between pt-5 items-stretch">
            <div className="*:text-balance flex flex-col bg-card p-5 py-8 font-medium rounded-xl gap-8 *:text-lg text-secondaryforeground">
              <p>
                Our core mission is to protect your information and
                infrastructure, empowering you to focus on driving your business
                forward confidently and securely. We recognise that success in
                today’s dynamic landscape demands more than just security—it
                requires resilience and adaptability.
              </p>
              <p>
                We are committed to excellence, integrity, and innovation in
                every solution we provide. Whether your business is evolving,
                expanding, or addressing complex challenges, we are here to
                ensure your success. With Smartsafe by your side, you can
                confidently navigate an ever-changing digital landscape and
                achieve extraordinary outcomes.
              </p>
            </div>
            <blockquote className="mt-20">
              <q className="text-3xl font-bold text-primary text-center">
                At Smartsafe Consulting, we are dedicated to enabling thousands
                of exceptional outcomes for our clients daily.
              </q>
            </blockquote>
          </article>
        </section>

        <section className="flex flex-col bg-white w-full p-12 gap-2">
          <h3 className="text-3xl text-center w-[65%] m-auto text-primary font-medium">
            Discover our unique Services that help you secure your business.
          </h3>
          <article className="pt-20">
            <AnimatePresence>
            <div className="grid grid-cols-[1fr_50px_1fr] place-items-center gap-8 bg-card rounded-xl">
              <div className="bg-primary h-full w-full  rounded-xl">
                <motion.img
                  initial={{opacity:0}}
                  animate={{opacity:1}}
                  transition={{duration:1.5}}
                  exit={{ opacity: 0, x: 50 }}
                  className="h-full w-full"
                  src={slideItems[currentSlide].icon}
                  alt=""
                />
              </div>
              <div className="flex flex-col gap-5">
                {slideItems.map((item, index) => (
                  <Button
                    key={item.heading}
                    className={`rounded-[100%] w-5 h-5 p-0 ${
                      currentSlide === index ? "bg-primary" : "bg-[#E1E1E4]"
                    } border-primary`}
                    onClick={() => setCurrentSlide(index)}
                  ></Button>
                ))}
              </div>
              <div className="p-6 flex justify-center flex-col gap-2">
                <h3 className="text-3xl font-medium text-primary">
                  {slideItems[currentSlide].heading}
                </h3>

                <p className="text-secondaryforeground text-lg">
                  {slideItems[currentSlide].details}
                </p>
              </div>
            </div>
            </AnimatePresence>
          </article>
        </section>

        <section className="flex flex-col bg-white w-full p-12 gap-2">
          <h3 className="text-3xl text-primary font-medium">
            Our Core Principles
          </h3>
          <p className="font-medium text-lg w-[80%]">
            We are committed to excellence, intergrity and innovation in every
            solution we provide which is guided by our eight unwavering
            principles.
          </p>
          <article className="grid grid-cols-4 bg-gray-50 mt-12 rounded-2xl gap-5 gap-y-12 justify-between  items-stretch">
            {Items.map((item, index) => (
              <motion.div
                variants={divItems}
                whileInView="show"
                initial="hidden"
                custom={index}
                viewport={{ once: true }}
                className="bg-card flex flex-col p-6 rounded-2xl items-center justify-center gap-8"
              >
                <img src={item.icon} alt="" />
                <p className="text-primaryforeground font-bold text-lg">
                  {item.title}
                </p>
              </motion.div>
            ))}
          </article>
        </section>
        <section className="flex flex-col bg-white w-full p-12 gap-2">
          <h3 className="text-3xl text-primary font-medium text-center">
            Frequently Asked Questions
          </h3>
          <p className="font-medium text-lg text-center">
            Your questions answered here.
          </p>
          <article className="mt-8">
            <Collapse
              className="w-full font-medium"
              bordered={false}
              defaultActiveKey={["1"]}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              expandIconPosition="right"
              items={getItems(panelStyle)}
            />
          </article>
        </section>
      </main>
    </WebsiteContainer>
  );
}

export default About;
