import React from 'react';
import { Avatar } from '../assest/icons';
import { ArrowRight, ArrowUpRight } from "lucide-react"


const Blog = ({BlogImage,ReadTime,Tag,BlogTitle,BlogDescription, Author, CreationDate}) => {
  return (
    <article className='flex flex-col shadow-transparent rounded-b-md'> 
                    <div>
                        <img src={BlogImage} alt=""/>
                    </div>
                    <div className='p-4 flex gap-3 flex-col rounded-b-md bg-[#F9F9F9]'>
                        <div className='flex '>
                            <p className='bg-card text-[#1F2358] flex items-center gap-2 py-1 px-2 text-xs font-medium rounded-3xl'> 
                                <span className='bg-white inline p-1 px-3 text-xs font-bold rounded-2xl'>{Tag}</span>
                               {ReadTime}
                            <ArrowRight className='w-5'/> 
                          </p>
                        </div>
                        <div>
                            <h3 className='text-xl justify-between font-bold flex'>
                               {BlogTitle}<ArrowUpRight/> 
                            </h3>
                        </div>
                        <p className='text-sm text-[#667085] font-medium'>
                          {BlogDescription}
                        </p>
                        <div className='flex gap-2 mt-5'>
                            <img className='w-10 h-10 rounded-[100%]' src={Avatar} alt=""/>
                             <div className='text-sm'>
                                <h4 className='font-medium'>{Author}</h4>
                                <p className='text-[#667085] font-medium'>{CreationDate}</p>
                             </div>
                        </div>
                    </div>
                </article>              
  )
}

export default Blog
