import { Button, Col, Image, Row } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const ServiceIndividual = ({ services }) => {
    const navigate = useNavigate();
  return (
    <Row gutter={[20, 20]}  className='bg-white'>
        {services.map((service, i) => {
            return (
                <Col lg={8} sm={12} xs={24} key={i} className='bg-white min-h-[320px] hoverEffect'>
                    <div className='py-6 flex flex-col gap-4 px-10 bg-[#FBFBFB] h-full'>
                        <div className='flex items-center gap-6'>
                            <div className='bg-[#D8DAFF] rounded-[5px] h-[65px] w-[65px] flex items-center justify-center'>
                                <Image 
                                    src={service.image} 
                                    alt={service.title} 
                                    preview={false} 
                                    className='bg-[#D8DAFF] h-[40px] w-[40px]' 
                                    style={{width: 40, height: 40}}
                                />
                            </div>
                            
                            <p className='text-primary text-xl font-semibold'>{service.title}</p>
                        </div>
                        <p>{service.description}</p>
                        <Button className='h-[50px] w-[150px] mt-4' onClick={() => navigate(`/services/${service.slug}/${service.id}`)}>
                            Learn More
                        </Button>
                    </div>
                    
                </Col>
            )
        })}
    </Row>
  )
}

export default ServiceIndividual