import { CaretDownFilled } from '@ant-design/icons'
import { Collapse } from 'antd'
import React from 'react'
import { frequentlyAskedQuestions } from '../../utils/pageDetails'

const Faqs = () => {
    const childStyle = {
        fontSize: 12,
        fontWeight: "400",
        lineHeight: "20px",
        textAlign: "left"
    }
    const item = [
        ...frequentlyAskedQuestions.map((faq, index) => (
            {
                key: index.toString(),
                label: faq.question,
                children: (<p style={{...childStyle, textAlign: "left"}} className='bg-white'>{faq.answer}</p>),   
            }
        ))
    ]
  return (
    <div>
        <div className='flex-col items-center gap-4 text-center mb-10'>
            <p className='text-primary font-medium text-3xl'>Frequently Asked Questions</p>
            <p className='text-[#1E1E1E] text-lg'>Your questions answered here.</p>
        </div>
        <Collapse
            bordered={false}
            // defaultActiveKey={['1']} 
            
            expandIcon={({ isActive }) => <CaretDownFilled style={{fontSize: 16}} className="text-2xl" rotate={isActive ? 90 : 0} />}
            style={{
                background: "#FFF",
            }}
            items={item}
            expandIconPosition="end"
            ghost
            // key={index}
        />  
    </div>
  )
}

export default Faqs