import React, { useEffect, useState } from 'react'
import { serviceItemList } from '../../utils/pageDetails';
import ServiceIndividual from './ServiceIndividual';
import { Tabs } from 'antd';

const ServiceCard = () => {
    const [ activeKey, setActiveKey ] = useState("1");
    const [ selectedServices, setSelectedServices ] = useState([]);

    useEffect(() => {
        const selectedItem = serviceItemList.filter(items => items.service_id === 1);
        setSelectedServices(selectedItem)
    }, []);
    
    const tabItems = [
        {
            key: "1",
            label: "Cybersecurity Consulting Services",
            children: (
                <ServiceIndividual 
                    services={selectedServices}
                />
            )
        },
        {
            key: "2",
            label: "Operational Technology Security Services",
            children: (
                <ServiceIndividual 
                    services={selectedServices}
                />
            )
        },
        {
            key: "3",
            label: "Data Privacy Services",
            children: (
                <ServiceIndividual 
                services={selectedServices}
                />
            )
        },
        {
            key: "4",
            label: "Technology Services",
            children: (
                <ServiceIndividual 
                services={selectedServices}
                />
            )
        },
        
    ]

    const handlekeyChange = (key) => {
        setActiveKey(key);
        const selectedItem = serviceItemList.filter(items => items.service_id === Number(key));
        setSelectedServices(selectedItem)
    }
  return (
    <div className='flex flex-col gap-6'>
        <p className='font-medium text-3xl text-center text-[#1E1E1E]'>Our Services</p>
        <Tabs 
            items={tabItems}
            defaultActiveKey={activeKey}
            activeKey={activeKey}
            onChange={(value) => handlekeyChange(value)}
            // style={{ backgroundColor: "#fff", overflowX: "auto" }}
            // className='website_tabs'

        />
    </div>
  )
}

export default ServiceCard