const theme = {
    token: {
      colorPrimary: '#1F2358',
      colorPrimaryBg: "#fff",
      fontFamily: "'Montserrat', 'DM Sans', sans-serif", 
    },
    components: {
        Button: {
          colorPrimary: '#1F2358',
          colorPrimaryBg: "#1F2358", 
          borderRadius: 5,
          controlHeight: 55,
          contentFontSize: 14,
          fontWeight: 700,
          paddingInline: 25,
          defaultBg: "#3DAFE2",
          defaultColor: "#ffffff",
          defaultBorderColor:"#3DAFE2",
          defaultActiveBg:"#3DAFE2",
        },
        Form:{
          labelColor:'#FFFFFF',
          labelFontSize: 18,
          itemMarginBottom: 30
        },
        Collapse:{
          contentBg: '#FFFFFF'
        }
    }
  };
  
  export default theme;