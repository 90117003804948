import React, { useEffect, useState } from 'react'
import WebsiteContainer from '../components/WebsiteContainer'
import Faqs from '../components/extras/Faqs'
import ReadyToElevate from '../components/cards/ReadyToElevate'
import { useNavigate, useParams } from 'react-router-dom'
import { imageBakground, serviceItemList } from '../utils/pageDetails'
import { Button, Col, Image, Row } from 'antd'
import ServiceIndividual from '../components/cards/ServiceIndividual'
import Bannercard from '../components/cards/Bannercard'
import { Freecons } from '../assest/image/service'
import { ArrowBg } from '../assest/image/background'

const IndividualService = () => {
    const { service, id } = useParams();
    
    const navigate = useNavigate();
    const [ otherServiceItems, setOtherServiceItems ] = useState([]);
    const [ selectedItems, setSelectedItems ] = useState(null);

    
    console.log("d", selectedItems);
        
    useEffect(() => {
        if(id) {
            const serviceItem = serviceItemList.find(serviceDetails => serviceDetails.id === Number(id));
            const otherServiceItem = serviceItemList.filter(serviceDetails => serviceDetails.slug === service && serviceDetails.id !== Number(id));

            setOtherServiceItems(otherServiceItem);
            setSelectedItems(serviceItem);
        }
    }, [id, service]);
  return (
    <WebsiteContainer active="services">
        <div className='pb-6'>
            <div 
                style={{
                    backgroundImage: `url(${imageBakground})`, 
                    backgroundColor: "#F7F7FF", 
                    height: 500
                }} 
                className="bg-repeat relative bg-blend-multiply bg-transparent px-20 py-6 flex-col justify-center items-center pb-6">
                <Row gutter={[0]} className='h-full relative'>
                    <Col lg={14} sm={12} xs={24}  className="flex flex-col gap-3 justify-center h-full">
                        <p className="text-primary font-bold text-[64px] leading-[5rem]">{selectedItems && selectedItems.title}</p>
                        <p className='text-[#555555] text-xl w-5/6'>{selectedItems && selectedItems.description}</p>

                        <div className='mt-6'>
                            <Button type='primary' style={{height: 50}}>
                                Book a Free Consultation
                            </Button>
                        </div>
                    </Col>
                    <img src={ArrowBg} alt='arrowbg' className='absolute z-10 left-[25%] bottom-[15%] rotate-3 z-0' />

                    <Col lg={10} sm={12} xs={24} className='flex justify-center items-center relative z-[1]'>
                        {/* <Image src={Arrow} preview={false} alt='arrow' style={{position: "absolute"}} className='absolute left-0' /> */}
                        <Image 
                            src={selectedItems && selectedItems.banner1} 
                            alt={selectedItems && selectedItems.title}  
                            preview={false} 
                            style={{height: 450, width: 450}}
                        />
                    </Col>
                </Row>
            </div>
            <div className='py-6 bg-white'>
                <Bannercard 
                    title={`What to Expect from Smartsafe’s ${selectedItems && selectedItems.title}`}
                    options={selectedItems && selectedItems.whatToExpect}
                    banner={selectedItems && selectedItems.banner2}
                    reverse
                />
            </div>

            <div className='py-6 bg-white'>
                <Bannercard 
                    title="Why Choose Smartsafe?"
                    options={selectedItems && selectedItems.whyChoose}
                    banner={Freecons}
                />
            </div>

            <div className='py-6 bg-white'>
                <Bannercard 
                    title="Why is it Worth Investing in Such a Service?"
                    options={selectedItems && selectedItems.whyIsItWorth}
                    banner={selectedItems && selectedItems.banner3}
                    reverse
                />
            </div>
            <div className='z-[1] relative'>
                <ReadyToElevate />
            </div>
            <div className='bg-white px-20 py-10 flex flex-col gap-8'>
                <p className='text-primary font-medium text-3xl text-center'>Explore other services</p>

                <ServiceIndividual services={otherServiceItems} />

                <div className="flex items-center justify-center mb-6 mt-[-20px] w-full">
                    <Button className='h-[50px]' type="primary" onClick={() => navigate("/services")}>
                        Back to all Services
                    </Button>
                </div>
                
            </div>
            <div className='bg-white px-20 py-5'>
                <Faqs />
            </div>
        </div>
    </WebsiteContainer>
  )
}

export default IndividualService