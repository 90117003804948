import { Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

const ReadyToElevate = () => {
  return (
    <div className='bg-[#F7F7FF] h-[360px] flex flex-col items-center justify-center'>
        <p className='text-primary text-2xl font-bold'>Ready to Elevate to the Next Level?</p>
        <p className='text-[#3A3B44] w-1/2 text-lg text-center'>Begin your journey to strengthen cyber resilience, enhance your security posture, and minimize cyber risks with our all-encompassing Security Advisory services.</p>

        <Link to="/contact">
          <Button 
            type='primary'
            className='h-[50px] mt-6'
          >
            Contact Us
          </Button>
        </Link>
    </div>
  )
}

export default ReadyToElevate