import React from 'react'
import WebsiteContainer from '../components/WebsiteContainer'
import Faqs from '../components/extras/Faqs'
import ReadyToElevate from '../components/cards/ReadyToElevate'
import ServiceBlueCard from '../components/cards/ServiceBlueCard'
import { ServiceImg } from '../assest/image'
import { Button, Image } from 'antd'
import { imageBakground } from '../utils/pageDetails'

const Services = () => {
  return (
    <WebsiteContainer active="services" showBgImg>
        <div className='pb-6'>
            <div style={{backgroundImage: `url(${imageBakground})`, backgroundColor: "#F7F7FF", height: 600}} className='px-20 flex items-center justify-left bg-[#F7F7FF] py-6 relative h-[600px] pb-14 bg-repeat bg-blend-multiply bg-transparent'>
                <div  className='flex flex-col gap-4 w-3/5'>
                    <p className="text-primary font-bold text-[64px] leading-[5rem]">Our Services</p>
                    <p className='text-[#555555] text-xl w-4/5'>We provide cutting-edge IT security solutions and comprehensive support services that help organisations achieve their business objectives while mitigating risks.</p>

                    <div className='mt-6'>
                        <Button type='primary' style={{height: 50}}>
                            Book a Free Consultation
                        </Button>
                    </div>
                </div>

                <div className='flex items-center justify-start  w-1/2 absolute right-[70px] top-[0px]'>
                <div >
                <Image src={ServiceImg} alt="side menu" preview={false} style={{width: "600px", height: "600px"}} />
                </div>
                
                </div>
            </div>

            <div className='bg-white px-20 py-5 pt-16'>
                <ServiceBlueCard />
            </div>
            <div className='bg-white px-20 py-5'>
                <Faqs />
            </div>
            <div>
                <ReadyToElevate />
            </div>
        </div>
    </WebsiteContainer>
  )
}

export default Services