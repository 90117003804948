import './App.css';
import 'tailwindcss/tailwind.css';
import Home from './pages/Home';
import About from './pages/About'
import Contact from './pages/Contact';
import Project from './pages/Project';
import Blogs from './pages/Blogs';
import { ConfigProvider } from 'antd';
import theme from './theme/themeConfig';
import {Routes, Route } from "react-router-dom";
import Services from './pages/Services';
import ServiceItem from './pages/ServiceItem';
import IndividualService from './pages/IndividualService';

function App() {
  return (
    <ConfigProvider theme={theme}>
      <Routes>
        <Route path="/" element={ <Home />} />
        <Route path="/services" element={ <Services />} />
        <Route path="/services/:id" element={ <ServiceItem />} />
        <Route path="/services/:service/:id" element={ <IndividualService />} />
        <Route path="/about" element={ <About />} />
        <Route path="/contact" element={ <Contact/>} />
        <Route path="/project" element={ <Project/>} />
        <Route path="/blogs" element={ <Blogs/>} />
      </Routes>
    </ConfigProvider>
  );
}

export default App;
