import { Col, Image, Row } from 'antd'
import React from 'react'
import { Dots } from '../../assest/image/service'
import { LeftEllipse, RightEllipse } from '../../assest/image/background'

const Bannercard = ({
    title,
    options,
    banner,
    reverse
}) => {
  return (
    <div className='bg-white md:px-32 relative py-4'  >
        {!reverse && <Row className='h-full' >
            <Col lg={14} sm={12} xs={24}  className="flex flex-col gap-3 justify-center h-auto">
                <p className="text-primary font-bold text-[40px] leading-tight">{title}</p>

                <ul>
                    {options &&  options.map((option, index) => {
                        return (
                            <ul key={index} className='text-[#555555] text-xl flex gap-2 items-start '>
                                <Image 
                                    src={Dots} 
                                    alt='dot' 
                                    className='text=xs mt-2.5' 
                                    style={{height: 5, width: 5}} 
                                />
                                <span>{option}</span>
                                
                            </ul>
                        )
                    })}
                </ul>
            </Col>

            <Col lg={10} sm={12} xs={24} className='flex justify-center items-center h-auto w-auto'>
                <Image 
                    src={banner} 
                    alt={title}  
                    preview={false} 
                    className='h-full w-full'
                    style={{height: 400, width: 400}}
                />
            </Col>
            <img src={RightEllipse} alt='left arrow' className='absolute right-0 top-0 z-0' /> 
        </Row>}

        {reverse && <Row className='h-full' >
            <Col lg={10} sm={12} xs={24} className='flex justify-center items-center h-auto w-auto z-[1]'>
                <Image 
                    src={banner} 
                    alt={title}  
                    preview={false} 
                    className='h-full w-full'
                    style={{height: 400, width: 400}}
                />
            </Col>
            <Col lg={14} sm={12} xs={24}  className="flex flex-col gap-3 justify-center h-auto">
                <p className="text-primary font-bold text-[40px] leading-tight">{title}</p>

                <ul>
                    {options &&  options.map((option, index) => {
                        return (
                            <ul key={index} className='text-[#555555] text-xl flex gap-2 items-start '>
                                <Image 
                                    src={Dots} 
                                    alt='dot' 
                                    className='text=xs mt-2.5' 
                                    style={{height: 5, width: 5}} 
                                />
                                <span>{option}</span>
                                
                            </ul>
                        )
                    })}
                </ul>
            </Col>
            <img src={LeftEllipse} alt='left arrow' className='absolute left-0 top-0 ' /> 
        </Row>}
    </div>
  )
}

export default Bannercard