import { Aagolf, AagolfLogo, Imv, ImvLogo, Nnlg, NnlgLogo, Slp, SlpLogo } from "../assest/image/projectmg"
import { BP1, BP2, BP3, C_1, C_2, C_3, DA1, DA2, DA3, DB1, DB2, DB3, DD1, DD2, DD3, DP1, DP2, DP3, DPM1, DPM2, DPM3, GG1, GG2, GG3, I1, I2, I3, ITM1, ITM2, ITM3, N1, N2, N3, OT1, OT2, OT3, P1, P2, P3, R1, R2, R3, SD1, SD2, SD3, SR1, SR2, SR3, T1, T2, T3, V1, V2, V3, v_1, V_2, V_3 } from "../assest/image/service"
import { C1, C10, C11, C12, C13, C14, C15, C16, C17, C18, C19, C2, C3, C4, C5, C6, C7, C8, C9, Ccs, Dps, Otss, Ts } from "../assest/image/serviceimages"

export const serviceItemList = [
    {
        id: 1,
        title: "Risk Assessment & Management",
        description: "With our expert-driven solutions designed to fortify resilience, you can identify, evaluate, and mitigate risks before they impact your organisation’s growth",
        service_id: 1,
        image: C1,
        slug: "cybersecurity-consulting-services",
        whatToExpect: [
            "Comprehensive identification of potential risks across operations, technology, and compliance domains.",
            "Detailed risk evaluation report with actionable insights.",
           "Customised risk management strategies that align with your organisational objectives.",
            "Continuous monitoring and support to adapt to new challenges."
        ],
        whyChoose: [
            "We have developed expertise in global risk assessment frameworks like ISO 31000 and COSO ERM.",
            "We have a proven track record of delivering results for organisations across industries.",
            "We provide tailored solutions that fit your organisation’s unique risk profile.",
            "We offer continual updates and support to keep your strategy ahead of emerging threats."
        ],
        whyIsItWorth: [
            "Minimise downtime and financial losses caused by unmitigated risks.",
           " Achieve compliance with relevant regulations, reducing legal and financial liabilities.",
            "Build stakeholder trust by demonstrating a proactive risk management approach.",
            "Enhanced decision-making based on clear, prioritised risk data.",
            "Reduction in risk exposure by up to 60% within the first assessment cycle."
        ],
        banner1: R1,
        banner2: R2,
        banner3: R3
    },
    {
        id: 2,
        title: "Vulnerability Management Service",
        description: "We empower organisations with proactive identification and mitigation of vulnerabilities to ensure a secure and resilient digital environment.",
        service_id: 1,
        image: C2,
        slug: "cybersecurity-consulting-services",
        whatToExpect: [
            "Comprehensive vulnerability scans across your existing IT infrastructure.",
            "Detailed risk assessments with prioritised recommendations.",
            "Seamless integration with your existing security tools for efficient management.",
            "Regular reports outlining identified vulnerabilities and their remediation status."
        ],
        whyChoose: [
            "We provide expert-led vulnerability assessments tailored to your organisation’s specific needs.",
            "Our advanced tools and methodologies deliver precise and actionable results.",
            "We offer continuous support and training to empower your internal teams.",
            "We have a proven track record of helping businesses strengthen their security posture."
        ],
        whyIsItWorth: [
            "Proactiveness: Identify and address vulnerabilities before they can be exploited.",
            "Improved Compliance: Align with standards such as PCI DSS, ISO 27001, or GDPR.",
            "Cost Prevention: Avoid expensive breaches and minimise downtime through early detection.",
            "Resilience: Build a secure IT environment that supports business continuity."
        ],
        banner1: V1,
        banner2: V2,
        banner3: V3
    },
    {
        id: 3,
        title: "IT Security Auditing",
        description: "Uncover vulnerabilities, bolster defences, and ensure your IT environment is secure with Smartsafe's expert IT Security Auditing services.",
        service_id: 1,
        image: C3,
        slug: "cybersecurity-consulting-services",
        whatToExpect: [
            "Comprehensive assessments of IT infrastructure, applications, and processes.",
            "A detailed audit report highlighting vulnerabilities, risks, and actionable recommendations.",
            "A remediation roadmap with prioritised high-impact actions.",
            "Ongoing consultation to support the implementation of suggested improvements.",
            "Gap analysis against industry standards such as NIST and COBIT."
        ],
        whyChoose: [
            "Certified expert auditors with extensive experience across various industries.",
            "Tailored audits to fit your organisation's size and regulatory requirements.",
            "A proven track record of helping numerous clients achieve compliance and strengthen security postures."
        ],
        whyIsItWorth: [
            "Protect sensitive data: Safeguard critical systems and information from breaches.",
            "Regulatory compliance: Gain confidence in meeting industry and legal requirements.",
            "Enhanced performance: Address inefficiencies to improve overall system performance.",
            "Risk reduction: Minimise your attack surface with actionable improvement plans."
        ],
        banner1: I1,
        banner2: I2,
        banner3: I3
    },
    {
        id: 4,
        title: "Cloud and Enterprise Security Architecture",
        description: "Building resilient systems to protect your business in the cloud and beyond.",
        service_id: 1,
        image: C4,
        slug: "cybersecurity-consulting-services",
        whatToExpect: [
            "A comprehensive security architecture design tailored to your business needs.",
            "Integration of security controls into existing and new cloud infrastructure.",
            "Risk assessment and threat modelling for enterprise systems.",
            "Continuous monitoring and optimisation of your security framework."
        ],
        whyChoose: [
            "We are experts in designing and implementing secure cloud environments.",
            "We apply leading frameworks like NIST and CIS for architecture development.",
            "Our dedicated team provides end-to-end support, from planning to execution.",
            "We have a proven track record of protecting businesses across multiple industries."
        ],
        whyIsItWorth: [
            "Minimised Downtime: Reduce disruptions caused by security breaches.",
            "Regulatory Compliance: Achieve compliance, lowering penalties and reputational risks.",
            "Enhanced Visibility: Gain control over cloud and on-premises environments.",
            "Scalability: Build an architecture that grows with your business needs."
        ],
        banner1: C_1,
        banner2: C_2,
        banner3: C_3
    },
    {
        id: 5,
        title: "Third-Party Risk Management a Service",
        description: "Empowering organisations to mitigate risks from third-party engagements by leveraging Smartsafe's expertise in continuous monitoring, compliance alignment, and tailored risk strategies.",
        service_id: 1,
        image: C5,
        slug: "cybersecurity-consulting-services",
        whatToExpect: [
            "Comprehensive third-party risk assessments.",
            "Continuous monitoring of vendor compliance.",
            "Tailored risk mitigation strategies.",
            "Regular reporting on third-party performance and risks."
        ],
        whyChoose: [
            "Proven expertise in managing complex third-party ecosystems.",
            "Access to proprietary risk management tools and frameworks.",
            "Customised solutions tailored to your industry and operational needs.",
            "Dedicated support from certified risk experts."
        ],
        whyIsItWorth: [
            "Risk Reduction: Minimise third-party risks by up to 70% through proactive measures.",
            "Compliance Assurance: Ensure adherence to industry standards like GDPR, ISO 27001, and PCI DSS.",
            "Efficiency Gains: Save time and resources with Smartsafe’s automated risk management solutions.",
            "Stakeholder Trust: Build confidence by demonstrating robust third-party governance."
        ],
        banner1: T1,
        banner2: T2,
        banner3: T3
    },
    {
        id: 6,
        title: "NCSC Cyber Assessment Framework (CAF)",
        description: "We help organisations align with the NCSC Cyber Assessment Framework, ensuring a robust cybersecurity posture and resilience against evolving threats.",
        service_id: 2,
        image: C6,
        slug: "operational-technology-security-services",
        whatToExpect: [
            "Comprehensive assessment of your organisation's cybersecurity practices.",
            "A detailed gap analysis report.",
            "Tailored recommendations to meet CAF requirements.",
            "Implementation support to enhance compliance."
        ],
        whyChoose: [
            "We bring extensive expertise in implementing the NCSC Cyber Assessment Framework across diverse industries.",
            "Our proven track record demonstrates efficient and effective compliance achievement.",
            "We deliver tailored, client-focused approaches that address unique operational needs."
        ],
        whyIsItWorth: [
            "Enhanced Protection: Strengthen defences against cyber threats.",
            "Standards Alignment: Comply with NCSC standards, boosting trust among stakeholders.",
            "Improved Resilience: Build operational resilience and enhance incident response capabilities.",
            "Cost-Effective Risk Mitigation: Reduce risks without unnecessary expenses.",
            "Better Audit Scores: Achieve improved compliance audit results."
        ],
        banner1: N1,
        banner2: N2,
        banner3: N3
    },
    {
        id: 7,
        title: "Physical Security Assessment / OT Site Assessment",
        description: "Smartsafe ensures your physical and operational technology (OT) environments are secure, reliable, and resilient against emerging threats.",
        service_id: 2,
        image: C7,
        slug: "operational-technology-security-services",
        whatToExpect: [
            "Comprehensive site inspections and vulnerability assessments.",
            "A detailed report with findings and risk levels.",
            "Customised mitigation strategies and action plans.",
            "Compliance support aligned with industry standards (e.g., NIST, IEC 62443)."
        ],
        whyChoose: [
            "Proven expertise in securing physical and OT environments.",
            "Customised solutions designed to meet industry-specific needs.",
            "Integration of cutting-edge technologies and best practices.",
            "A trusted partner in compliance and risk management."
        ],
        whyIsItWorth: [
            "Enhanced Protection: Safeguard critical infrastructure and assets.",
            "Minimised Downtime: Reduce disruptions caused by security incidents.",
            "Improved Compliance: Meet regulatory and safety standards.",
            "Increased Confidence: Boost stakeholder trust in operational resilience.",
            "Global Standards Alignment: Receive recommendations that follow global best practices."
        ],
        banner1: P1,
        banner2: P2,
        banner3: P3
    },
    {
        id: 8,
        title: "Vulnerability Testing",
        description: "Uncover and mitigate hidden threats before they become critical vulnerabilities.",
        service_id: 2,
        image: C8,
        slug: "operational-technology-security-services",
        whatToExpect: [
            "Comprehensive scanning and analysis of systems, applications, and networks.",
            "Automated and manual vulnerability assessments to ensure thorough coverage.",
            "A detailed report outlining identified vulnerabilities, their impact, and recommended remediation strategies.",
            "Expert guidance to help implement fixes and reduce risk effectively."
        ],
        whyChoose: [
            "We boast a team of experienced cybersecurity professionals who apply proven methodologies for vulnerability testing and management.",
            "Our tools and frameworks align with leading standards such as OWASP, NIST, and ISO 27001, ensuring precision and reliability.",
            "We provide tailored recommendations that align with your specific business objectives.",
            "As a trusted partner in cybersecurity, we have a proven track record of successful client outcomes."
        ],
        whyIsItWorth: [
            "Proactive risk prevention: Address vulnerabilities before they can be exploited.",
            "Enhanced compliance: Meet and exceed industry security standards and regulations.",
            "Strengthened customer trust: Demonstrate a commitment to protecting sensitive data.",
            "Measurable impact: Reduce risk exposure by up to 75% following remediation."
        ],
        banner1: v_1,
        banner2: V_2,
        banner3: V_3
    },
    {
        id: 9,
        title: "Security Risk Assessment for Industrial Control Systems (ICS)",
        description: "Safeguarding industrial operations through robust, tailored risk assessments to ensure the resilience and integrity of your organisation’s control systems.",
        service_id: 2,
        image: C9,
        slug: "operational-technology-security-services",
        whatToExpect: [
            "Comprehensive assessments of ICS networks and endpoints.",
            "Identification and prioritisation of vulnerabilities based on risk.",
            "Tailored mitigation strategies to address identified threats.",
            "Detailed reports highlighting potential threats and compliance gaps.",
            "Guidance on implementing security controls without disrupting operations."
        ],
        whyChoose: [
            "Extensive expertise in securing critical infrastructure.",
            "Specialised knowledge of ICS-specific vulnerabilities and attack scenarios.",
            "Proven methodologies that balance security with operational efficiency.",
            "Customised solutions for diverse industrial sectors."
        ],
        whyIsItWorth: [
            "Enhanced visibility into ICS vulnerabilities and attack vectors.",
            "Improved system resilience against cyberattacks.",
            "Reduced risk of operational downtime and costly disruptions.",
            "Confidence in meeting compliance standards like IEC 62443 and NERC CIP.",
            "Proactive defence mechanisms designed for critical infrastructure needs."
        ],
        banner1: SR1,
        banner2: SR2,
        banner3: SR3
    },
    {
        id: 10,
        title: "OT Network and Solution Design Validation",
        description: "Smartsafe ensures operational technology networks are secure, reliable, and optimised for performance through expert design validation.",
        service_id: 2,
        image: C10,
        slug: "operational-technology-security-services",
        whatToExpect: [
            "A comprehensive review and validation of your OT network architecture.",
            "Identification and resolution of design vulnerabilities.",
            "Tailored recommendations for optimising network performance and security.",
            "A detailed validation report highlighting findings and actionable steps for improvement."
        ],
        whyChoose: [
            "Expertise in Industrial Control Systems (ICS) and OT environments: Our professionals bring deep insights into securing and optimising OT networks.",
            "Proven track record: We’ve successfully delivered secure and efficient network solutions across diverse industries.",
            "Cutting-edge tools and methodologies: We employ advanced technologies to ensure precise validation and optimisation.",
            "Customised solutions: We tailor our services to align with your organisation's unique needs."
        ],
        whyIsItWorth: [
            "Reduced Downtime: Mitigate costly operational disruptions.",
            "Enhanced Security: Protect your OT networks from cyber threats.",
            "Regulatory Compliance: Align with industry-specific standards such as IEC 62443.",
            "Improved Reliability: Build a robust network infrastructure for seamless operations."
        ],
        banner1: OT1,
        banner2: OT2,
        banner3: OT3
    },
    {
        id: 11,
        title: "Data Breach Management",
        description: "Proactively manage and mitigate data breaches with Smartsafe's expert solutions, ensuring your organisation stays resilient against evolving threats.",
        service_id: 3,
        image: C11,
        slug: "data-privacy-services",
        whatToExpect: [
            "A dedicated incident response team is available to address breaches promptly.",
            "Comprehensive forensic analysis to uncover the cause and extent of the breach.",
            "Detailed action plans for containment, recovery, and future prevention.",
            "Training programmes for staff to enhance breach detection and prevention capabilities."
        ],
        whyChoose: [
            "Proven Expertise: We have a strong track record of managing high-profile breach incidents.",
            "Tailored Solutions: Our response plans are customised to meet your organisation’s unique needs.",
            "Cutting-Edge Technology: We employ the latest tools for breach detection and mitigation.",
            "Comprehensive Support: We ensure seamless management at every stage from containment to recovery."
        ],
        whyIsItWorth: [
            "Minimised Disruption: Contain and recover quickly to keep operations running smoothly.",
            "Regulatory Compliance: Avoid fines and lawsuits by aligning with data protection standards.",
            "Enhanced Preparedness: Empower your team with tools and strategies to prevent future incidents.",
            "Cost Efficiency: Significantly reduce the financial impact of data breaches through swift action."
        ],
        banner1: DB1,
        banner2: DB2,
        banner3: DB3
    },
    {
        id: 12,
        title: "Data Privacy Audit and Compliance",
        description: "Protecting your organisation's data is not just a responsibility; it’s a competitive advantage.",
        service_id: 3,
        image: C12,
        slug: "data-privacy-services",
        whatToExpect: [
            "Comprehensive audits of data collection, processing, storage, and sharing practices.",
            "Identification of compliance gaps and risks.",
            "Tailored recommendations for achieving full compliance.",
            "Training sessions to build internal awareness.",
            "A roadmap to maintain ongoing compliance and trust."
        ],
        whyChoose: [
            "Proven expertise in auditing and implementing data privacy frameworks.",
            "A team of certified professionals with global and local regulatory knowledge.",
            "Tailored solutions to meet the unique needs of your industry.",
            "Support beyond the audit, including ongoing consultation and updates to stay ahead of evolving regulations."
        ],
        whyIsItWorth: [
            "Compliance: Avoid fines and legal repercussions by ensuring adherence to regulations.",
            "Trust: Build confidence with customers, partners, and stakeholders.",
            "Security: Strengthen data protection frameworks to prevent breaches.",
            "Efficiency: Improve operational efficiency by aligning data handling with best practices.",
            "Awareness: Enhance employee understanding of data privacy requirements.",
            "Documentation: Provide documented proof of compliance readiness for regulators."
        ],
        banner1: DP1,
        banner2: DP2,
        banner3: DP3
    },
    {
        id: 13,
        title: "DPO-as-a-Service",
        description: "Expert guidance to safeguard your organisation's data privacy and ensure compliance with global data protection standards.",
        service_id: 3,
        image: C13,
        slug: "data-privacy-services",
        whatToExpect: [
            "Ongoing guidance on compliance with data protection laws.",
            "Comprehensive data audits to identify and mitigate privacy risks.",
            "Development of policies and procedures tailored to your organisation’s needs.",
            "Incident response planning and support.",
            "Training and awareness programmes for employees."
        ],
        whyChoose: [
            "A proven track record of helping organisations achieve and maintain compliance.",
            "Expertise across multiple jurisdictions and industries.",
            "Customised strategies aligned with your business goals and processes.",
            "Ongoing support and updates on the changing regulatory landscape."
        ],
        whyIsItWorth: [
            "Regulatory Compliance: Ensure adherence to global and regional data privacy regulations.",
            "Risk Mitigation: Protect your organisation’s reputation by avoiding data breaches and penalties.",
            "Cost Efficiency: Access experienced data protection professionals without the overhead of a full-time hire.",
            "Trust Building: Improve client and stakeholder trust by demonstrating a strong commitment to privacy."
        ],
        banner1: DA1,
        banner2: DA2,
        banner3: DA3
    },
    {
        id: 14,
        title: "Data Privacy Management",
        description: "Ensuring your organisation's compliance with data protection laws while safeguarding customer trust and business integrity.",
        service_id: 3,
        image: C14,
        slug: "data-privacy-services",
        whatToExpect: [
            "Comprehensive data privacy assessments and risk analyses.",
            "Development and implementation of data protection policies.",
            "Staff training and awareness programmes to promote compliance.",
            "Regular audits and updates to ensure ongoing adherence to regulations.",
            "Reporting frameworks designed to demonstrate compliance readiness."
        ],
        whyChoose: [
            "We are experts in both global and local data protection regulations.",
            "We provide tailored solutions suitable for organisations of any size or industry.",
            "We have a proven track record of helping businesses achieve compliance efficiently.",
            "We employ cutting-edge tools and frameworks for effective data privacy management."
        ],
        whyIsItWorth: [
            "Reduced Risk: Mitigate the likelihood of data breaches and associated penalties.",
            "Improved Trust: Strengthen customer trust and loyalty through proactive privacy measures.",
            "Operational Efficiency: Streamline data management practices for better performance.",
            "Competitive Advantage: Showcase a commitment to privacy that sets you apart in your industry.",
            "Compliance Gap Reduction: Significantly decrease compliance gaps within six months of implementation."
        ],
        banner1: DPM1,
        banner2: DPM2,
        banner3: DPM3

    },
    {
        id: 15,
        title: "GDPR Gap Analysis",
        description: "Helping organisations identify and close gaps in their GDPR compliance framework, ensuring alignment with data protection regulations and safeguarding their reputation.",
        service_id: 3,
        image: C15,
        slug: "data-privacy-services",
        whatToExpect: [
            "Comprehensive assessment of your current GDPR compliance status.",
            "A detailed gap analysis report highlighting areas of non-compliance.",
            "Actionable recommendations to address identified gaps.",
            "Support in prioritising remediation steps based on risk and impact."
        ],
        whyChoose: [
            "Expertise in GDPR compliance, with proven methodologies tailored to different industries.",
            "A team of certified data protection professionals.",
            "A customised approach ensuring recommendations align with your organisational goals.",
            "Post-analysis support to guide you through remediation."
        ],
        whyIsItWorth: [
            "Avoid Penalties: Minimise the risk of hefty GDPR fines and penalties.",
            "Build Trust: Strengthen trust and credibility with customers by ensuring secure data handling.",
            "Improve Efficiency: Align organisational processes with GDPR standards to enhance efficiency.",
            "Enhance Protection: Protect against data breaches by identifying and addressing vulnerabilities.",
            "Reduce Compliance Risks: Significantly lower the likelihood of non-compliance issues."
        ],
        banner1: GG1,
        banner2: GG2,
        banner3: GG3
    },
    {
        id: 16,
        title: "Software Development Services",
        description: "Empowering businesses with tailored software solutions to streamline operations, enhance efficiency, and drive innovation.",
        service_id: 4,
        image:  C16,
        slug: "technology-services",
        whatToExpect: [
            "Comprehensive requirement analysis and consultation.",
            "Fully customised software tailored to your business needs.",
            "Seamless integration with your existing systems.",
            "Scalable and secure solutions designed for growth.",
            "Ongoing support and maintenance."
        ],
        whyChoose: [
            "We provide a team of experienced developers proficient in modern technologies.",
            "We deliver high-quality, scalable solutions tailored to your needs.",
            "We prioritise user-centric designs to ensure ease of adoption.",
            "We are committed to timelines and maintain transparent communication throughout the project lifecycle."
        ],
        whyIsItWorth: [
            "Enhanced Productivity: Achieve efficiency through tailored workflows.",
            "Cost Reduction: Optimise processes to lower operational expenses.",
            "Improved Security: Ensure compliance with specific standards for every solution.",
            "Long-Term Savings: Avoid recurring licence fees associated with off-the-shelf products."
        ],
        banner1: SD1,
        banner2: SD2,
        banner3: SD3
    },
    {
        id: 17,
        title: "Business Process Modelling (BPM)",
        description: "Empowering organisations to visualise, analyse, and optimise their workflows for greater efficiency and performance.",
        service_id: 4,
        image: C17,
        slug: "technology-services",
        whatToExpect: [
            "A comprehensive analysis of your organisation’s workflows.",
            "Detailed process maps that highlight inefficiencies and bottlenecks.",
            "Practical recommendations for process optimisation.",
            "Training and implementation support for new and improved workflows."
        ],
        whyChoose: [
            "We leverage advanced process modelling tools and methodologies.",
            "We have a proven track record of delivering measurable results.",
            "Our solutions are customised to meet your organisation’s specific needs.",
            "We provide extensive post-implementation support and training."
        ],
        whyIsItWorth: [
            "Improved Efficiency: Streamline processes to enhance operational efficiency.",
            "Cost Savings: Reduce operational costs and save valuable time.",
            "Better Alignment: Ensure business objectives are aligned with daily activities.",
            "Increased Agility: Adapt swiftly to market or regulatory changes."
        ],
        banner1: BP1,
        banner2: BP2,
        banner3: BP3
    },
    {
        id: 18,
        title: "Database Development",
        description: "Manage your data with Smartsafe’s expert-driven database development services, designed to optimise workflows and unlock powerful insights.",
        service_id: 4,
        image: C18,
        slug: "technology-services",
        whatToExpect: [
            "Tailored database designs that align with your organisation’s specific needs.",
            "Seamless integration with existing systems and third-party tools.",
            "Scalable solutions to support long-term organisational growth.",
            "Thorough security measures to safeguard sensitive data.",
            "Detailed documentation and training to enable smooth adoption."
        ],
        whyChoose: [
            "Over a decade of experience in database development and management.",
            "Solutions customised to meet the unique requirements of your organisation.",
            "A proven track record of delivering projects on time and within budget.",
            "Use of cutting-edge technologies and industry best practices."
        ],
        whyIsItWorth: [
            "Streamlined Data Access: Enhance operational efficiency through optimised workflows.",
            "Scalability: Implement solutions that grow alongside your organisation.",
            "Enhanced Security: Minimise risks of data breaches with top-tier protections.",
            "Reduced Downtime: Eliminate inefficiencies associated with outdated systems."
        ],
        banner1: DD1,
        banner2: DD2,
        banner3: DD3
    },
    {
        id: 19,
        title: "IT Modernisation through Cloud Solution",
        description: "Empower your organisation with scalable, secure, and efficient cloud solutions that optimise your IT infrastructure and drive innovation.",
        service_id: 4,
        image: C19,
        slug: "technology-services",
        whatToExpect: [
            "Assessment of current IT systems to identify modernisation opportunities.",
            "A customised migration strategy to cloud platforms like AWS, Azure, or Google Cloud.",
            "Seamless data migration with minimal disruption to operations.",
            "Integration of advanced cloud tools for collaboration and security.",
            "Ongoing support and optimisation post-migration.",
        ],
        whyChoose: [
            "Proven track record in cloud migration and modernisation projects.",
            "Certified cloud experts with extensive experience in AWS, Azure, and Google Cloud.",
            "Tailored solutions designed to align with your business goals.",
            "Commitment to data security and regulatory compliance."
        ],
        whyIsItWorth: [
            "Cost Savings: Save up to 30% on IT maintenance costs by leveraging cloud-based solutions.",
            "Scalability: Scale resources up or down based on demand.",
            "Enhanced Productivity: Boost productivity with cloud-based collaboration tools.",
            "Security: Benefit from robust, cloud-native security features.",
            "Resilience: Reduce downtime and improve disaster recovery capabilities."
        ],
        banner1: ITM1,
        banner2: ITM2,
        banner3: ITM3
    }
]

export const serviceDetails = [
    {
        id: 1,
        title: "Cybersecurity Consulting Services",
        description: "Empower your organisation with scalable, secure, and efficient cloud solutions that optimise your IT infrastructure and drive innovation.",
        image: Ccs,
        slug: "cybersecurity-consulting-services"
    },
    {
        id: 2,
        title: "Operational Technology Security Services",
        description: "Safeguarding industrial operations through robust, tailored risk assessments to ensure the resilience and integrity of your organisation’s control systems.",
        image: Otss,
        slug: "operational-technology-security-services"
    },
    {
        id: 3,
        title: "Data Privacy Services",
        description: "Expert guidance to safeguard your organisation's data privacy and ensure compliance with global data protection standards.",
        image: Dps,
        slug: "data-privacy-services"
    },
    {
        id: 4,
        title: "Technology Services",
        description: "Empowering organisations to visualise, analyse, and optimise their workflows for greater efficiency and performance.",
        image: Ts,
        slug: "technology-services"
    }
]

export const projectListItems = [
    {
        id: 1,
        name: "AAGOLF",
        description: "AAGOLF is a non profit golfing group, consisting of corporate members playing Golf together, where every member have a stake in the group.",
        logo: AagolfLogo,
        image: Aagolf,
        url: "https://aagolf.co.uk/"
    },
    {
        id: 2,
        name: "Smart Learning UK",
        description: "Smart Learning UK specializes in providing job-ready online training focused on IT security through hands-on and project-based learning.",
        logo: SlpLogo,
        image: Slp,
        url: "https://www.smartlearninguk.com/"

    },
    {
        id: 3,
        name: "Imovarsity",
        description: "IMV will equip people and organizations with accredited, future-proof skills that make them employable, competitive, and ready for the evolving job market.",
        logo: ImvLogo,
        image: Imv,
        url: "https://www.imovarsity.com/"
    },
    {
        id: 4,
        name: "Nigeria LNG Limited",
        description: "Nigeria LNG Limited (NLNG) is a liquefied natural gas (LNG)-producing company and a liquefied natural gas plant on Bonny Island, Nigeria.",
        logo: NnlgLogo,
        image: Nnlg,
        url: "https://sap-frontend-production.herokuapp.com/"
    }
]

export const frequentlyAskedQuestions = [
    {
        id: 1, 
        question: "What does Smartsafe Consulting do?",
        answer: "Smartsafe Consulting is dedicated to enabling exceptional outcomes for clients daily by protecting their information and infrastructure, allowing them to focus on growing their business confidently and securely.",
    },
    {
        id: 2, 
        question: "What is Smartsafe Consulting's core mission?",
        answer: "Our core mission is to protect your information and infrastructure, empowering you to drive your business forward confidently and securely.",
    },
    {
        id: 3, 
        question: "What makes Smartsafe Consulting different?",
        answer: "We recognize that success requires more than just security; it demands resilience and adaptability in today's dynamic landscape.  We provide the tools and expertise to achieve both.",
    },
    {
        id: 4, 
        question: "How does Smartsafe Consulting help businesses?",
        answer: "We help businesses by safeguarding their critical information and infrastructure, enabling them to operate without fear of cyber threats and focus on their core business objectives.",
    },
    {
        id: 5, 
        question: "What kind of outcomes can I expect from Smartsafe Consulting?",
        answer: "We are dedicated to enabling thousands of exceptional outcomes for our clients daily.  This means improved security, enhanced resilience, and the confidence to grow your business without being held back by security concerns.",
    }
]

export const imageBakground = "https://s3-alpha-sig.figma.com/img/7e2c/a331/0560ae01b3c4e60fa8000fd0e6cc25c9?Expires=1743379200&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=U-y~spB5o2fmq~ncz2~ojz5a~0CBISEugHTfQh06gF8J6AMDJlST9ya8eZlp2jhpfI6sl9B0ywrnBxFwjS~morHXs0s2IbO~MRJ7sByev4V9osPvWhcb4fYPm0tgNxHkOxNkv~0IYGoX~pKFWW-QlaibKmtzrfYOwGjxbbKBbHOvpSDkUyjWyhyYbPRmyvcYnPEgbRljf41ZJHfHtcvd745ZbuqNz~gE-itnHYpSXXLuRcR2Msq~7YwJfxCWCwH~w5tnG93qwlGWdzt5N9axSoRr5GDS2iy7GdWuUJ5J~vLBcjxjJJTkg1lHxBxNgrqQxcAONmNr9EdIcXzzqmLARQ__"